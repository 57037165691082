/**
 * @flow
 */

import { action, observable, computed, toJS } from "mobx";
import _debounce from "lodash/debounce";

import { PedidoError } from "./Pedido";
import Item, { DESCONTO, MIN_LITROS } from "./Item";

export const META_VALID = "META_VALID";
export const META_INVALID = "META_INVALID";
export const META_NEUTRAL = "META_NEUTRAL";

const reduceVolume = (acc, item) => acc + Math.max(+item.fator_conversao, MIN_LITROS) * +item.quantidade;
const reduceLata = (acc, item) => acc + +item.quantidade;
// const reducePeso = (acc, item) => acc + Math.max(+item.peso_bruto, 0) * +item.quantidade;

export class MetaKit {
  @observable codigo_kit: string = null;
  @observable codigo_classe: string = null;
  @observable desc_classe: string = null;
  @observable grupo_cor: string = null;
  @observable desc_grupo_cor: string = null;
  @observable linha: string = null;
  @observable desc_linha: string = null;
  @observable meta_proporcao: number = 0.0;
  @observable qtd_min: number = 0.0;
  @observable adicional: number = 0.0;
  @observable qtd_mpr: string = null;
  @observable soma_qtd_min: string = null;
  @observable volating: number = 0.0;
  @observable propkit: number = 0.0;

  // eslint-disable-next-line
  @observable kit: Kit = null;

  constructor(kit, meta) {
    this.kit = kit;
    this.codigo_kit = meta.codigo_kit;
    this.codigo_classe = meta.codigo_classe;
    this.desc_classe = meta.desc_classe;
    this.grupo_cor = meta.grupo_cor || "";
    this.desc_grupo_cor = meta.desc_grupo_cor || "";
    this.linha = meta.linha;
    this.desc_linha = meta.desc_linha;
    this.meta_proporcao = +meta.meta_proporcao || 0.0;
    this.qtd_min = +meta.qtd_min || 0.0;
    this.adicional = +meta.adicional || 0.0;
    this.qtd_mpr = meta.qtd_mpr || "NAO";
    this.soma_qtd_min = meta.soma_qtd_min || "NAO";
    this.volating = +meta.volating || 0.0;
    this.propkit = +meta.propkit || 0.0;
  }

  @computed
  get volatingStatus() {
    if (+this.qtd_min > 0) {
      return +this.volating > 0 && +this.volating < +this.qtd_min ? META_INVALID : META_VALID;
    }

    return META_NEUTRAL;
  }

  @computed
  get propkitStatus() {
    if (+this.meta_proporcao > 0) {
      return +this.propkit < +this.meta_proporcao ? META_INVALID : META_VALID;
    }

    return META_NEUTRAL;
  }

  @computed
  get isValid() {
    return this.volatingStatus !== META_INVALID && this.propkitStatus !== META_INVALID;
  }

  @computed
  get asJSON() {
    const { kit, ...meta } = this;
    return toJS(meta);
  }
}

export default class Kit {
  static today: string = null;

  @observable codigo_kit: string = null;
  @observable titulo_kit: string = null;
  @observable operacao_frete: string = null;
  @observable modalidade_frete: string = null;
  @observable dt_kit_inicial: string = null;
  @observable dt_kit_final: string = null;
  @observable dt_prog_inicial: string = null;
  @observable dt_prog_final: string = null;
  @observable quantidade_total: number = 0.0;
  @observable adicional_qtd_minima: number = 0.0;
  @observable tipo_qtd_total: string = null;
  @observable qtd_inicial: number = 0.0;
  @observable qtd_final: number = 0.0;
  @observable proporcao: number = 0.0;
  @observable qtd_min_proporcao: number = 0.0;
  @observable prazo_medio_solvente: number = 0.0;
  @observable prazo_medio_tinta: number = 0.0;
  @observable regiao_vendedor: string = null;
  @observable produto_fora_kit: string = "NAO";
  @observable tipo_transporte: string = null;
  @observable local_vld_proporcao: string = null;
  @observable calc_acresc_condpag: string = "NAO";
  @observable calc_descre_condpag: string = "NAO";

  @observable items: Array<Item> = [];
  @observable metas: Array<MetaKit> = [];
  @observable proporcaoKit: Array = [];
  @observable abrangenciaKit: Array = [];

  constructor(data = null, items = [], metas = [], proporcaoKit = [], abrangenciaKit = []) {
    this.debouncedCalculaMetas = _debounce(this.calculaMetas.bind(this), 120);

    if (!!data) {
      this.parse(data, items, metas, proporcaoKit, abrangenciaKit);
    }
  }

  @action
  parse(data, items = [], metas = [], proporcaoKit = [], abrangenciaKit = []) {
    this.codigo_kit = data.CampKit.codigo_kit;
    this.titulo_kit = data.CampKit.titulo_kit;
    this.operacao_frete = data.CampKit.operacao_frete || "C";
    this.modalidade_frete = data.CampKit.modalidade_frete || "RODOVIARIO";
    this.dt_kit_inicial = !!data.CampKit.dt_kit_inicial ? data.CampKit.dt_kit_inicial.split(" ")[0] : "";
    this.dt_kit_final = !!data.CampKit.dt_kit_final ? data.CampKit.dt_kit_final.split(" ")[0] : "";
    this.dt_prog_inicial = !!data.CampKit.dt_prog_inicial ? data.CampKit.dt_prog_inicial.split(" ")[0] : "";
    this.dt_prog_final = !!data.CampKit.dt_prog_final ? data.CampKit.dt_prog_final.split(" ")[0] : "";
    this.quantidade_total = Math.max(+data.CampKit.quantidade_total, 0);
    this.adicional_qtd_minima = Math.max(+data.CampKit.adicional_qtd_minima, 0);
    this.tipo_qtd_total = data.CampKit.tipo_qtd_total || "Nenhum";
    this.qtd_inicial = Math.max(+data.CampKit.qtd_inicial, 0);
    this.qtd_final = Math.max(+data.CampKit.qtd_final, 0);
    this.proporcao = Math.max(+data.CampKit.proporcao, 0);
    this.qtd_min_proporcao = Math.max(+data.CampKit.qtd_min_proporcao, 0);
    this.prazo_medio_solvente = Math.max(+data.CampKit.prazo_medio_solvente, 0) || 0;
    this.prazo_medio_tinta = Math.max(+data.CampKit.prazo_medio_tinta, 0) || 0;
    this.regiao_vendedor = data.CampKit.regiao_vendedor;
    this.produto_fora_kit = data.CampKit.produto_fora_kit || "NAO";
    this.tipo_transporte = data.CampKit.tipo_transporte || null;
    this.local_vld_proporcao = data.CampKit.local_vld_proporcao;
    this.calc_acresc_condpag = data.CampKit.calc_acresc_condpag || "NAO";
    this.calc_descre_condpag = data.CampKit.calc_descre_condpag || "NAO";
    if (!!items && items.length > 0) this.items = items;
    if (!!metas && metas.length > 0) this.metas = metas;
    if (!!proporcaoKit && proporcaoKit.length > 0) this.proporcaoKit = proporcaoKit;
    if (!!abrangenciaKit && abrangenciaKit.length > 0) this.abrangenciaKit = abrangenciaKit;
  }

  @action
  reset() {
    this.codigo_kit = null;
    this.titulo_kit = null;
    this.operacao_frete = null;
    this.modalidade_frete = null;
    this.dt_kit_inicial = null;
    this.dt_kit_final = null;
    this.dt_prog_inicial = null;
    this.dt_prog_final = null;
    this.quantidade_total = 0.0;
    this.adicional_qtd_minima = 0.0;
    this.tipo_qtd_total = null;
    this.qtd_inicial = 0.0;
    this.qtd_final = 0.0;
    this.proporcao = 0.0;
    this.qtd_min_proporcao = 0.0;
    this.prazo_medio_solvente = 0.0;
    this.prazo_medio_tinta = 0.0;
    this.regiao_vendedor = null;
    this.produto_fora_kit = "NAO";
    this.tipo_transporte = null;
    this.local_vld_proporcao = null;
    this.calc_acresc_condpag = "NAO";
    this.calc_descre_condpag = "NAO";
    this.items = [];
    this.metas = [];
    this.proporcaoKit = [];
    this.abrangenciaKit = [];
  }

  @action
  calculaMetas() {
    if (this.metas.length > 0) {
      for (let j = 0, jc = this.metas.length; j < jc; j++) {
        const meta = this.metas[j];
        meta.volating = Math.max(
          this.items
            .filter(item => {
              if (!!meta.linha) {
                if (!!meta.grupo_cor) {
                  return (
                    item.grupo_cor === meta.grupo_cor &&
                    item.codigo_linha === meta.linha &&
                    item.classe_produto.indexOf(meta.codigo_classe) === 0
                  );
                }

                return item.codigo_linha === meta.linha && item.classe_produto.indexOf(meta.codigo_classe) === 0;
              }

              if (!!meta.grupo_cor) {
                return item.grupo_cor === meta.grupo_cor && item.classe_produto.indexOf(meta.codigo_classe) === 0;
              }

              return item.classe_produto.indexOf(meta.codigo_classe) === 0;
            })
            .reduce(this.tipo_qtd_total === 'Latas' ? reduceLata : reduceVolume, 0),
          0
        );
        meta.propkit = Math.max((meta.volating / this.volatingTotal) * 100, 0);
      }
    }
  }

  @computed
  get tipoDesconto() {
    if (this.items.length > 0) {
      return this.items[0].tipoDescontoCampanha;
    }
    return DESCONTO;
  }

  @computed
  get volatingTotal() {
    if (this.items.length > 0) {
      return this.items.reduce(this.tipo_qtd_total === 'Latas' ? reduceLata : reduceVolume, 0);
    }
    return 0;
  }

  @computed
  get pesoTotal() {
    if (this.items.length > 0) {
      return this.items.reduce(this.tipo_qtd_total === 'Latas' ? reduceLata : reduceVolume, 0);
    }
    return 0;
  }

  @computed
  get validItemsTotal() {
    return this.items.filter(item => +item.quantidade > Math.max(+item.campKit.qtd_min || 0, 0) && !!item.isValid).length;
  }

  @computed
  get isMinimumValid() {
    const invalidItems = this.items.filter(item => +item.quantidade < Math.max(+item.campKit.qtd_min || 0, 0)).length;

    if (this.validItemsTotal >= 0 && invalidItems === 0) {
      if (this.metas.length > 0 && this.metas.filter(meta => !meta.isValid).length > 0) {
        return false;
      }

      return true;
    }
    /*
    const invalidItems = this.items.filter(item => +item.quantidade < Math.max(+item.campKit.qtd_min || 0, 0)).length;

    if (this.validItemsTotal >= 0 && invalidItems === 0) {
      if (this.metas.length > 0 && this.metas.filter(meta => meta.isValid).length === 0) {
        return false;
      }

      return true;
    }
    */
    return false;
  }

  @computed
  get canUseDescGeren() {
    return !!this.items.filter(item => !!item.policy && Math.max(+item.perc_desc_gerencial, 0) > 0).length;
  }

  @computed
  get descGerenQtd() {
    return this.items.filter(item => Math.max(+item.desc_geren, 0) > 0).length;
  }

  validateKit() {
    if (this.isMinimumValid) {
      const qtd_inicial = Math.max(+this.qtd_inicial, 0);
      const qtd_final = Math.max(+this.qtd_final, 0);
      if (!!this.tipo_qtd_total && this.tipo_qtd_total.toUpperCase() === "LATAS" && qtd_final > 0) {
        const qtd_latas = this.items.reduce((acc, item) => acc + Math.max(+item.quantidade, 0), 0);
        if (qtd_latas < qtd_inicial || qtd_latas > qtd_final) {
          throw new PedidoError(
            `Qtde de latas precisa estar entre a qtd. min (${qtd_inicial}) e qtd. max (${qtd_final}) para esta campanha!`
          );
        }
      }
      if (!!this.tipo_qtd_total && this.tipo_qtd_total.toUpperCase() === "VOLUME" && qtd_final > 0) {
        if (this.volatingTotal < qtd_inicial || this.volatingTotal > qtd_final) {
          throw new PedidoError(
            `O volume precisa estar entre a qtd. min (${qtd_inicial}) e qtd. max (${qtd_final}) para esta campanha!`
          );
        }
      }
      if (this.metas.length > 0) {
        if (+this.qtd_min_proporcao > 0) {
          const metas_soma = this.metas.filter(meta => meta.soma_qtd_min.toUpperCase() === "SIM");
          if (metas_soma.length > 0) {
            const volatingTotal = metas_soma.reduce((acc, meta) => acc + Math.max(+meta.volating, 0), 0);
            if (volatingTotal < +this.qtd_min_proporcao) {
              throw new PedidoError(`Qtd. Mínima Prop. (${this.qtd_min_proporcao}) não atendida!`);
            }
          }
        }
        if (+this.proporcao > 0) {
          const metas_soma = this.metas.filter(meta => meta.qtd_mpr.toUpperCase() === "SIM");
          if (metas_soma.length > 0) {
            const propkitTotal = metas_soma.reduce((acc, meta) => acc + Math.max(+meta.propkit, 0), 0);
            if (propkitTotal < +this.proporcao) {
              throw new PedidoError(`Proporção (${this.proporcao}%) não atendida para o Kit!`);
            }
          }
        }
      }
      return true;
    }
    return false;
  }

  toggleAllDescGeren(evento) {
    const ic = this.items.length;
    const action = !evento.target.checked ? "none" : (this.descGerenQtd >= this.validItemsTotal ? "none" : "all");
    for (let i = 0; i < ic; i++) {
      if (action === "all" && +this.items[i].quantidade > 0 && +this.items[i].perc_desc_gerencial > 0) {
        this.items[i].desc_geren = Math.max(+this.items[i].perc_desc_gerencial, 0);
      } else {
        this.items[i].desc_geren = 0;
      }
      this.items[i].calculaPreco();
    }
  }

  @computed
  get asJSON() {
    const { items, metas, ...kit } = this;

    return {
      ...toJS(kit),
      items: items.map(item => item.asJSON),
      metas: metas.map(meta => meta.asJSON),
    };
  }
}
