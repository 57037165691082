import React, { Component } from "react";
import { Modal, Row, Col, Form, Input, Button, Spin } from "antd";
import { observer } from "mobx-react";

import { fetchApi, fetchPostApi } from "actions";
import { getData } from "@util";
import withStore from "withStore";

import { v } from "components/FormBuilder";
import PageHeader, { BackButton } from "components/PageHeader";
import { MaskedInput } from "antd-mask-input";

const FormItem = Form.Item;

@observer
class UsersCargoAddScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTransportadora: false,
    };
  }
  async componentDidMount() {
    if (this.props.match.params.edit !== "add") {
      await this.fetchBase();
    }
  }
  async fetchBase() {
    this.props.store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/transportadores/usercargo/edit/" + this.props.match.params.edit);
      if (!!response.success) {
        const { transportadora, nome, cpf, telefone, email, login, senha, isTransportadora } = response.data;
        this.props.form.setFieldsValue({
          transportadora,
          nome,
          cpf,
          telefone,
          email,
          login,
          senha,
        });
        this.setState({ isTransportadora })
      } else {
        throw new Error("Não foi possível obter dados atualizados para este formulário");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível obter dados atualizados para este formulário",
        onOk: () => this.props.history.replace("/transportadores/users-cargo"),
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }

  async saveUserCargo(data) {
    const { store, history, match } = this.props;

    const errorMessages = {
      1: "Falha ao cadastrar com esse login. Tente outro.",
      2: "Falha ao cadastrar o motorista nessa transportadora.",
      10: "Não foi possível registrar o motorista neste momento. Tente novamente mais tarde",
    };

    store.isLoading = true;

    try {
      if (match.params.edit !== "add") {
        data = {
          ...data,
          codigo: match.params.edit,
        };
      }
      const { data: response } = await fetchPostApi(
        "/transportadores/usercargo/" + (match.params.edit === "add" ? "save" : "update"),
        data
      );
      const msgSaveOrEdit =
        match.params.edit === "add" ? "Motorista registrado com sucesso" : "Motorista atualizado com sucesso";
      if (!!response.success) {
        Modal.success({
          title: msgSaveOrEdit,
          onOk() {
            history.replace("/transportadores/users-cargo");
          },
          keyboard: false,
        });

        store.isLoading = false;
      } else {
        throw new Error(errorMessages[10]);
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || (typeof err === "string" ? err : errorMessages[10]),
      });

      store.isLoading = false;
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveUserCargo(Object.keys(formData).reduce((acc, k) => ({ ...acc, [k]: formData[k] || "" }), {}));
      }
    });
  };
  render() {
    const { isLoading } = this.props.store;
    const { getFieldDecorator } = this.props.form;
    const { match } = this.props;

    const inputProps = {
      readOnly: isLoading,
      autoComplete: "off",
    };

    const isAdd = match.params.edit === "add";

    return (
      <div className="cadastro-motorista-screen">
        <PageHeader
          title={isAdd ? "Novo Motorista" : `Editar motorista #${match.params.edit}`}
          headerLeft={<BackButton onClick={() => this.props.history.goBack()} />}
        />
        <Spin spinning={!!isLoading}>
          <Form
            autoComplete="off"
            layout="vertical"
            hideRequiredMark={false}
            onSubmit={this.handleSubmit}
            className="cadastro-cliente--form">
            <div>
              <Row gutter={12}>
                <Col span={9}>
                  <FormItem label="Transportadora">
                    {getFieldDecorator("transportadora", { rules: [v.required] })(
                      <Input disabled={this.state.isTransportadora} placeholder="Transportadora" maxLength={4} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={9}>
                  <FormItem label="Nome">
                    {getFieldDecorator("nome", { rules: [v.required] })(
                      <Input placeholder="Nome" maxLength={100} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="CPF">
                    {getFieldDecorator("cpf", { rules: [v.required, v.cpf] })(
                      <Input placeholder="CPF" maxLength={11} onKeyPress={this.handleKeyPress} {...inputProps} />
                      // <MaskedInput mask="111.111.111-11" onKeyPress={this.handleKeyPress} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={3}>
                  <FormItem label="Telefone">
                    {getFieldDecorator("telefone", { rules: [v.required] })(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="E-mail">
                    {getFieldDecorator("email", { rules: [v.email, v.required] })(
                      <Input placeholder="E-mail" maxLength={100} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={3}>
                  <FormItem label="Login">
                    {getFieldDecorator("login", { rules: [v.required] })(
                      <Input placeholder="Login" maxLength={30} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem label="Senha">
                    {getFieldDecorator("senha", {
                      rules: [v.required],
                    })(<Input placeholder="Senha" maxLength={30} {...inputProps} />)}
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 6, marginBottom: 16 }}>
              <Button
                style={{ marginTop: 9 }}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="cadastro-motorista--button">
                {isAdd ? "Enviar" : "Editar"} cadastro
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create()(withStore(UsersCargoAddScreen));
