import React, { PureComponent, Fragment } from "react";
import { DatePicker, Form, Spin } from "antd";
import { PieChart, Pie, Cell } from "recharts";
import styled from "styled-components";
import { lighten } from "polished";
import _isEqual from "lodash/isEqual";

import { n, dateFormat } from "@util";
import { fetchMeta } from "actions/dashboard";
import { PageTitle, PageTitleExtra, ChartGroup, ChartGroupTitle, ChartValue } from "./Styled";
import MetaCorridaPanel from "./MetaCorridaPanel";

const RADIAN = Math.PI / 180;

const MetaValue = styled.div`
  display: inline-block;
  background-color: ${lighten(0.05, "#312675")};
  padding: 6px 9px;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
  margin: 2px auto -2px;
  width: calc(1020px + 3.25%);
  text-align: center;
`;

const DashboardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0 26px;
`;

const MetaChart = ({ label, meta, chartValue, width = 509, height = 195, percent, children, ...props }) => {
  const pieRadius = {
    innerRadius: (width / 2) * 0.4,
    outerRadius: (width / 2) * 0.6,
  };

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: height * 0.8,
  };

  const arrowData = [{ value: Math.min(chartValue, meta) }, { value: 0 }, { value: Math.max(meta - chartValue, 0) }];

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + width * 0.095) * cos;
    const my = cy + (outerRadius + width * 0.095) * sin;

    return (
      <g>
        <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="5" stroke="#494949" fill="none" strokeLinecap="round" />
        <circle cx={cx} cy={cy} r={width * 0.035} fill="#363636" stroke="none" />
      </g>
    );
  };

  return (
    <ChartGroup>
      <ChartGroupTitle>{label}</ChartGroupTitle>
      <ChartValue>
        R$ <strong>{n(chartValue)}</strong>
      </ChartValue>
      <PieChart width={width} height={height}>
        <defs>
          <linearGradient id="colorGauge" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#CC0000" stopOpacity={1} />
            <stop offset="45%" stopColor="#ff9933" stopOpacity={1} />
            <stop offset="65%" stopColor="#dab318" stopOpacity={1} />
            <stop offset="100%" stopColor="#33cc33" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Pie dataKey="value" data={[{ value: 100 }]} fill="none" {...pieRadius} {...pieProps}>
          <Cell fill="url(#colorGauge)" />
        </Pie>
        <Pie
          stroke="none"
          activeIndex={1}
          activeShape={Arrow}
          dataKey="value"
          data={arrowData}
          outerRadius={pieRadius.innerRadius}
          fill="none"
          {...pieProps}
        />
      </PieChart>
      <ChartValue pct>{n(percent)}%</ChartValue>
    </ChartGroup>
  );
};

export default class MetaPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      mes: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.fetchData();
    }
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      let mes = null;

      if (!!this.state.mes) {
        mes = this.state.mes.month() + 1;
      }

      const response = await fetchMeta(!!this.props.relacionadoData ? this.props.relacionadoData.codigo : null, mes);
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, data } = this.state;
    return (
      <Spin spinning={isLoading}>
        <div style={{ minHeight: 280 }}>
          <PageTitle title={this.props.tipo} meta={false}>
            <PageTitleExtra>
              {!data ? "Carregando..." : `${dateFormat(data.data_inicial)} até ${dateFormat(data.data_final)}`}
            </PageTitleExtra>
          </PageTitle>
          <Form.Item>
            <DatePicker.MonthPicker
              placeholder="Selecione o mês"
              format="MMMM"
              onChange={mes => this.setState({ mes }, this.fetchData)}
            />
          </Form.Item>
          {!!data && (
            <Fragment>
              <MetaCorridaPanel tipo={"FAT"} data={data.meta} mes={this.state.mes ? this.state.mes.month() + 1 : new Date().getMonth() + 1} />
              <Fragment>
                {!!data.meta && +data.meta.meta > 0 && (
                  <div style={{ textAlign: "center" }}>
                    <MetaValue>
                      <strong>Objetivo do mês:</strong> R$ {n(data.meta.meta)}
                    </MetaValue>
                  </div>
                )}
                <DashboardBody>
                  {!!data.meta && +data.meta.meta > 0 ? (
                    <Fragment>
                      <MetaChart
                        label="Vendido"
                        meta={data.meta.meta}
                        chartValue={data.meta.vendido}
                        percent={data.meta.vendido_pct}
                      />
                      <MetaChart
                        label="Faturado"
                        meta={data.meta.meta}
                        chartValue={data.meta.faturado}
                        percent={data.meta.faturado_pct}
                      />
                    </Fragment>
                  ) : (
                    <p>
                      <strong>Dados insuficientes para montar o seu gráfico de Metas.</strong>
                    </p>
                  )}
                </DashboardBody>
                {!!data.meta && +data.meta.carteira > 0 && (
                  <div style={{ textAlign: "center" }}>
                    <MetaValue>
                      <strong>Carteira:</strong> R$ {n(data.meta.carteira)}
                    </MetaValue>
                  </div>
                )}
              </Fragment>
            </Fragment>
          )}
        </div>
      </Spin>
    );
  }
}
