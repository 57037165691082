import React, { Component, Fragment } from "react";
import { Alert, Modal, Button } from "antd";
import { observer } from "mobx-react";

import "./ProporcaoInfo.less";

@observer
export default class ProporcaoInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }
  open = () => this.setState({ visible: true });
  close = () => this.setState({ visible: false });
  render() {
    const { proporcao = null, atingida = 0, atingida2 = 0, abrangencia } = this.props;

    if (!proporcao || proporcao.length < 1) {
      return null;
    }

    const minimo = Math.max(+proporcao[0].proporcao_cabecalho, 0);
    const proporcao1 = proporcao.filter((elem) => elem.etapa === '1');
    const proporcao2 = proporcao.filter((elem) => elem.etapa === '2');
    const abrangencia1 = abrangencia.filter((elem) => elem.etapa === '1');
    const abrangencia2 = abrangencia.filter((elem) => elem.etapa === '2');
    const minimo2 = !!proporcao2.length ? Math.max(+proporcao2[0].proporcao_cabecalho, 0) : 0;
    const apoio = `Proporção mínima: ${minimo}% Realizado: ${atingida}%`;
    const apoio2 = `Proporção mínima: ${minimo2}% Realizado: ${atingida2}%`;

    const abrangenciaText = `Classes <strong>abrangidas</strong> por esta etapa:`; //FAZER UMA TABLE E UM TEXTO PARA FALAR QUAIS SÃO AS CLASSES ABRANGIDAS parecido como é a de proporção hoje

    return (
      <Modal
        centered
        width={800}
        title="Proporção do Pedido"
        visible={this.state.visible}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Button type="primary" onClick={this.close}>
              Voltar ao Pedido
            </Button>
          </div>
        }
        onCancel={this.close}
        destroyOnClose={true}
        maskClosable={true}
        wrapClassName="proporcao-info-modal">
        <Fragment>
          <table style={{ width: "100%"}}>
            <tr style={{ width: "100%" }}>
              {proporcao1.length > 0 && (
                <td style={{ height:"1px", width: "50%", textAlign:"center", verticalAlign:"center"}}>
                  <Alert
                  style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginRight: 15 }}
                  type={atingida >= minimo ? "success" : "error"}
                  message={apoio}
                  showIcon/>
                </td>
              )}
              {proporcao2.length > 0 && (
                <td style={{ height:"1px",  width: "100%" , textAlign:"center", verticalAlign:"center"}}>
                  <Alert
                  style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginRight: 15 }}
                  type={atingida2 >= minimo2 ? "success" : "error"}
                  message={apoio2}
                  showIcon/>
                </td>
              )}
            </tr>
            <tr>
              {proporcao1.length > 0 && (
                <td style={{ alignContent:"flex-start" }}>
                  <thead style={{ alignContent:"flex-start" }}>
                    <tr style={{ width: "100%" }}>
                      <th className="text-center">Etapa</th>
                      <th className="text-center">Linha</th>
                      <th className="text-center">Classe</th>
                      <th className="text-left">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proporcao1.map(p => (
                      <tr style={{ width: "100%" }} key={`${p.classe_produto}:${p.linha_produto}`}>
                        <td className="text-center" style={{ width: 80 }}>{p.etapa}</td>
                        <td className="text-center" style={{ width: 110 }}>{p.desc_linha}</td>
                        <td className="text-center" style={{ width: 80 }}>{p.classe_produto}</td>
                        <td className="text-left" style={{ width: "100%" }}>{p.desc_classe}</td>
                      </tr>
                    ))}
                  </tbody>
                </td>
              )}
              {proporcao2.length > 0 && (
                <td style={{ alignContent:"flex-start" }}>
                  <thead>
                    <tr style={{ width: "100%" }}>
                      <th className="text-center">Etapa</th>
                      <th className="text-center">Linha</th>
                      <th className="text-center">Classe</th>
                      <th className="text-left">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proporcao2.map(p => (
                      <tr style={{ width: "100%" }} key={`${p.classe_produto}:${p.linha_produto}`}>
                        <td className="text-center" style={{ width: 80 }}>{p.etapa}</td>
                        <td className="text-center" style={{ width: 110 }}>{p.desc_linha}</td>
                        <td className="text-center" style={{ width: 80 }}>{p.classe_produto}</td>
                        <td className="text-left" style={{ width: "100%" }}>{p.desc_classe}</td>
                      </tr>
                    ))}
                  </tbody>
                </td>
              )}
            </tr>
            <tr>
              {abrangencia1.length > 0 && (
                <td style={{ textAlign:"center" }} ><p dangerouslySetInnerHTML={{ __html: abrangenciaText }} style={{ marginTop: '1em' }} /></td>
              )}
              {abrangencia2.length > 0 && (
                <td style={{ textAlign:"center" }}><p dangerouslySetInnerHTML={{ __html: abrangenciaText }} style={{ marginTop: '1em' }} /></td>
              )}
            </tr>
            <tr>
              {abrangencia1.length > 0 && (
                <td style={{ alignContent:"flex-start" }}>
                  <thead>
                    <tr>
                      <th className="text-center">Etapa</th>
                      <th className="text-center">Linha</th>
                      <th className="text-center">Classe</th>
                      <th className="text-left">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {abrangencia1.map(p => (
                      <tr key={`${p.classe_produto}:${p.linha_produto}`}>
                        <td className="text-center" style={{ width: 80 }}>{p.etapa}</td>
                        <td className="text-center" style={{ width: 110 }}>{p.desc_linha}</td>
                        <td className="text-center" style={{ width: 80 }}>{p.classe_produto}</td>
                        <td className="text-left" style={{ width: "100%" }}>{p.desc_classe}</td>
                      </tr>
                    ))}
                  </tbody>
                </td>
              )}
              {abrangencia2.length > 0 && (
                <td style={{ alignContent:"flex-start" }}>
                  <thead>
                    <tr>
                      <th className="text-center">Etapa</th>
                      <th className="text-center">Linha</th>
                      <th className="text-center">Classe</th>
                      <th className="text-left">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {abrangencia2.map(p => (
                      <tr key={`${p.classe_produto}:${p.linha_produto}`}>
                        <td className="text-center" style={{ width: 80 }}>{p.etapa}</td>
                        <td className="text-center" style={{ width: 110 }}>{p.desc_linha}</td>
                        <td className="text-center" style={{ width: 80 }}>{p.classe_produto}</td>
                        <td className="text-left" style={{ width: "100%" }}>{p.desc_classe}</td>
                      </tr>
                    ))}
                  </tbody>
                </td>
              )}
            </tr>
          </table>
        </Fragment>
      </Modal>
    );
  }
}
