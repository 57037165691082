import React, { Component, Fragment } from "react";
import { Spin, Modal, Form, Input, Button, Icon } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";
import { toJS } from "mobx";
import classnames from "classnames";
import _isEqual from "lodash/isEqual";
import _uniq from "lodash/uniq";

import { flatObjectToOptions } from "@util";
import { fetchClasses } from "actions/orcamento";

import {
  SearchForm,
  Toolbar,
  ChooserTable,
  TableDataSource,
  WrappedValue,
  TableStaticDataSource,
  mapColumn,
} from "components/FullTable";

import "./ChooserGrade.less";

const MODE_LINHA = "MODE_LINHA";
const MODE_CLASSE = "MODE_CLASSE";
const MODE_PRODUTO = "MODE_PRODUTO";

const DescricaoCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataTag = styled.div`
  display: inline-block;
  background-color: #c00;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 3px 4px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

const TagCashBack = styled.div`
  display: inline-block;
  background-color: #195488;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

const rowKey = item => item.key || item.label || JSON.stringify(item);
const rowProdutoKey = item => `${item.Produto.codigo}:${item.Produto.codigo_linha}:${item.Produto.classe_produto}`;

const SubTitleH4 = styled.h4`
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid ${props => props.theme.borderColor};
  margin-top: 14px;
  margin-bottom: 15px;
`;

const FormItem = Form.Item;
const Search = Input.Search;

@observer
class SearchProdutoForm extends Component {
  render() {
    const { placeholder = "Buscar opção", dataSource, onSearch, isFardo } = this.props;

    const inputProps = {
      readOnly: dataSource.isLoading,
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={(_, m) => {
          if ("query" in m) onSearch(m);
        }}
        defaultFilters={{ isFardo }}
        filterFields={{ query: "text" }}
        className="chooser-produto-search">
        {({ form, submitForm }) => (
          <Fragment>
            <FormItem style={{ flex: 1 }}>
              {form.getFieldDecorator("query")(
                <Search
                  placeholder={placeholder}
                  enterButton
                  onSearch={event => setTimeout(submitForm, 30)}
                  {...inputProps}
                />
              )}
            </FormItem>
            {!!dataSource.hasFilter && (
              <FormItem>
                <Toolbar.Button size="default" icon="close" ghost={true} onClick={() => onSearch({})} />
              </FormItem>
            )}
          </Fragment>
        )}
      </SearchForm>
    );
  }
}

@observer
class ChooserSimple extends Component {
  static defaultProps = {
    items: [],
  };
  constructor(props) {
    super(props);

    this.ds = new TableStaticDataSource([]);

    this.state = {};
  }
  componentDidMount() {
    this.refreshItems();
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.items || [], this.props.items)) {
      this.refreshItems();
    }
  }
  refreshItems = () => {
    const { items = [] } = this.props;
    this.ds.items = Array.isArray(items) ? items : flatObjectToOptions(items);
  };
  render() {
    const { loading = false, title = "Selecione um tipo", ...otherProps } = this.props;

    const columns = [
      mapColumn({
        title,
        key: "label",
        dataIndex: "label",
        className: "text-left",
      }),
    ];

    return (
      <ChooserTable
        className="chooser-table__compact chooser-table__link"
        loading={loading}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        {...otherProps}
      />
    );
  }
}

@observer
export default class ChooserFardo extends Component {
  constructor(props) {
    super(props);

    this.carrinho = [];

    this.ds = new TableDataSource({
      name: "Encontre seu Produto",
      uri: "/cliente/orcamentos/produtos/" + (props.filial || "01"),
    });

    this.state = {
      loading: false,
      visible: false,
      onChoose: null,
      mode: MODE_LINHA,
      linha: null,
      classe: null,
      items: [],
      current: {},
      focused: null,
      tipo_produto: null,
      isDescCamp: false,
      isFardo: false
    };
  }
  open = (onChoose = null) => {
    this.ds.reset();

    this.updateDataSourceUri();

    this.setState({
      loading: false,
      visible: true,
      mode: MODE_LINHA,
      classesNiveis: {},
      linha: null,
      classe: null,
      items: [],
      onChoose,
      current: {},
      focused: null,
      tipo_produto: null,
      qtdItens: 0
    });
  };
  close = () => {
    this.setState({ visible: false, isDescCamp: false, isFardo: false });
    this.carrinho = []
  };

  saveCurrentValue = (item, field) => event => {
    const { current, tipo_produto, qtdItens } = this.state;
    const key = rowProdutoKey(item);
    const value = typeof event === "string" ? event : +event.target.value;
    let idlinha = '';


    if (qtdItens === 0 && +event.target.value === 0) {
      idlinha = null;
    }
    else if (qtdItens === 0 && tipo_produto === null && +event.target.value > 0) {
      idlinha = item.Produto.tipo_produto;
    }
    else if (qtdItens > 0 && (tipo_produto === 'LINHA' || tipo_produto === 'ESPECIAL')) {
      idlinha = tipo_produto;
    }
    else {
      idlinha = null;
    }

    this.setState({ focused: field + ":" + key, tipo_produto: idlinha, current: { ...current, [key]: { ...current[key], [field]: value, item: item } } });
  };

  hasChanged = (item, field) => {
    const { current } = this.state;
    const key = rowProdutoKey(item);

    return (
      !current[key] || (!!current[key] && (!current[key][field] || `${current[key][field]}` !== `${toJS(item.Produto[field])}`))
    );
  };
  checkQuantidade = item => {
    if (this.hasChanged(item, "quantidade")) {
      const { onPedidoError } = this.props;
      try {
        this.validaQuantidade(item);
      } catch (err) {
        onPedidoError(err.name === "PedidoError" && !!err.message ? err.message : `Quantidade inválida!`);
      } finally {
        //sucesso total
      }
    }
  };

  validaQuantidade(item) {
    const { cod_canal, status_pedido, onPedidoError } = this.props;
    const { current } = this.state;
    const __errors = [];
    const field = "quantidade";
    const key = rowProdutoKey(item);
    const quantidade = current[rowProdutoKey(item)].quantidade; //item.Produto.quantidade;

    // Desabilita arredondamento e qtd. min. em pedidos status = Amostra ou se o perfil do cliente for FUNCIONARIO
    if ((status_pedido === "5" && item.Produto.tipo_sku === "PA") || (cod_canal === "10") || quantidade === 0) {
      return;
    }

    if (+quantidade > 0) {
      const quantidade_por_fardo = Math.max(+item.Produto.quantidade_por_fardo, 1);
      const fardos = Math.max(Math.ceil(+quantidade / quantidade_por_fardo), 1);
      const quantidade_precisa = fardos * quantidade_por_fardo;
      if (quantidade_precisa !== +quantidade) {
        //item.Produto.quantidade = quantidade_precisa;
        this.setState({ current: { ...current, [key]: { ...current[key], [field]: quantidade_precisa, item: item } } });
        __errors.push("Não pode ser vendido fardo quebrado");
      }
    }

    if (__errors.length > 0) {
      onPedidoError(__errors.join(" / "));
    }
  }

  onUpdate = () => {
    const { onChoose, isDescCamp } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(this.carrinho, isDescCamp);
    }
    this.close();
  };

  updateDataSourceUri = () => {
    const { filial = "01", cliente, loja } = this.props;
    const { linha, classe } = this.state;

    let _freshUri = "/cliente/orcamentos/produtos/" + filial + "-" + cliente + '-' + loja;
    if (!!linha) _freshUri += "/" + (linha || "01");
    if (!!classe) _freshUri += "/" + (classe || "01");

    this.ds.uri = _freshUri;
  };
  searchProdutos = filters => {
    if (!!filters) {
      filters = { ...filters, isFardo: true }

      const freshState = (() => {
        if (!filters["query"] && !this.state.classe) {
          return { items: [], classe: null, linha: null, mode: MODE_LINHA, isDescCamp: this.state.isDescCamp };
        }

        return { mode: MODE_PRODUTO, isDescCamp: this.state.isDescCamp };
      })();

      this.setState(freshState);

      console.log('uai entrou aqui?')

      requestAnimationFrame(() => {
        if (freshState.mode === MODE_LINHA) {
          this.ds.reset();
        }

        console.log('antes do updateDataSourceUri?', this._chooserProduto);

        this.updateDataSourceUri();

        if (!!this._chooserProduto) {
          console.log('chooserProduto?')

          this._chooserProduto.updateDataSource(null, filters, null);
          this._chooserProduto.fetchData();
        }
      });
    }
  };
  selectLinha = async ({ value }) => {
    this.setState({ loading: true });

    const onError = () => {
      this.props.onOrcamentoError(`Não foi possível obter a lista de classes neste momento`);
      this.close();
    };

    try {
      const response = await fetchClasses(value);
      if (!!response.success) {
        this.setState({
          linha: value,
          classe: null,
          classesNiveis: response.data,
          loading: false,
          mode: MODE_PRODUTO,
        });
      } else {
        onError();
      }
    } catch (err) {
      onError();
    }
  };
  selectTipo = ({ value }) => {
    this.setState({ loading: true });

    setTimeout(
      () =>
        this.setState({ loading: false, classe: value, mode: MODE_PRODUTO, isFardo: value }, () => {
          this.updateDataSourceUri();

          if (this.state.mode === MODE_PRODUTO && !!this._chooserProduto) {
            this._chooserProduto.fetchData();
          }
        }),
      200
    );
  };
  onSelectItem = (...args) => {
    let achou = false;

    for (let i = 0; i < this.carrinho.length; i++) {
      console.log('this.carrinho[i].key, args[0].key -> ', this.carrinho[i].key, args[0].key);
      if (this.carrinho[i].key === args[0].key) {
        this.carrinho.splice(i, 1);
        this.setState({ qtdItens: this.carrinho.length });
        achou = true;
      }
    }

    if (!achou) {
      this.carrinho.push(args[0]);
      this.carrinho[this.carrinho.length - 1].Produto.fardo = this.carrinho[this.carrinho.length - 1].key;
      this.setState({ qtdItens: this.carrinho.length })
    }

    console.log('this.carrinho -> ', this.carrinho);
  };
  filteredClasses = () => {
    const { classesNiveis = {}, classe, linha } = this.state;

    if (!!linha) {
      if (!classe) {
        return flatObjectToOptions(classesNiveis.c1);
      } else {
        const clss = flatObjectToOptions(classe.length === 2 ? classesNiveis.c2 : classesNiveis.c3 || {});
        return clss.filter(item => item.value.startsWith(classe));
      }
    }

    return {};
  };
  stepBack = event => {
    if (!!event) event.preventDefault();

    this.setState({ items: [], classe: null, linha: null, mode: MODE_LINHA }, () => this.ds.reset());
  };
  renderTitle = () => {
    const { mode, linha, classe, classesNiveis = {} } = this.state;
    const { tipos_fardo = {} } = this.props;

    function titleClasses(titleBase) {
      const title = [titleBase];
      if (classe.length >= 2 && !!classesNiveis.c1) {
        title.push(classesNiveis.c1[classe.substr(0, 2)]);
      }
      if (classe.length >= 4 && !!classesNiveis.c2) {
        title.push(classesNiveis.c2[classe.substr(0, 4)]);
      }
      if (classe.length >= 6 && !!classesNiveis.c3) {
        title.push(classesNiveis.c3[classe.substr(0, 6)]);
      }
      return _uniq(title).join(" / ");
    }

    if (mode === MODE_CLASSE) {
      if (!!linha && !classe) {
        return "Classes da LINHA " + tipos_fardo[linha];
      }

      return titleClasses("Classes da LINHA " + tipos_fardo[linha]);
    }
    if (mode === MODE_PRODUTO) {
      if (!linha && !classe && !!this.ds.filters["query"]) {
        return "Filtrando produtos: " + this.ds.filters["query"];
      } else if (!!linha && !!classe) {
        return titleClasses("Produtos em LINHA " + tipos_fardo[linha]);
      }

      return "Escolha seu(s) Produto(s)";
    }

    return "Encontre seu Produto";
  };
  renderFooter = () => {
    const { mode, onChoose = null, tipo_produto, qtdItens } = this.state;
    const isLoading = !!this.props.isLoading || this.state.loading;

    if (mode === MODE_LINHA) {
      return null;
    }

    if (qtdItens === 0 && tipo_produto !== null) {
      this.setState({ tipo_produto: null });
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button onClick={this.stepBack} icon="left" disabled={isLoading}>
          Voltar
        </Button>
        {mode === MODE_PRODUTO && (
          <Button
            type="primary"
            loading={isLoading}
            disabled={qtdItens === 0}
            onClick={() => {
              if (!!onChoose && typeof onChoose === "function") {
                this.onUpdate()
              }
              this.close();
            }}>
            {qtdItens > 1 ? "Adicionar Itens" : "Adicionar Item"}
            {qtdItens > 0 ? ` (${qtdItens})` : ""}
          </Button>
        )}
      </div>
    );
  };
  render() {
    const { width = 875, tipos_fardo = {}, isLoading = false } = this.props;
    const { mode, visible = false, tipo_produto, qtdItens } = this.state;

    const COLUMNS_PRODUTOS = [
      {
        title: "Linha",
        key: "linha_produto",
        dataIndex: "Produto.linha_produto",
        width: "12.5%",
      },
      {
        title: "Tipo",
        key: "tipo_produto",
        dataIndex: "Produto.tipo_produto",
        width: "8%",
      },
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "Produto.codigo",
        width: "12%",
        className: "text-left",
      },
      {
        title: "Produto",
        key: "descricao",
        dataIndex: "Produto.descricao",
        className: "text-left",
        render: (text, item) => (
          <DescricaoCell>
            <WrappedValue value={text} type="text" style={{ minWidth: "60%" }} />
            {item.Produto.permite_tintometrico === 'NAO' && <DataTag>Apenas Clientes Tintométrico</DataTag>}
            {item.Produto.venda_liberada === 'NAO' && <DataTag>Venda bloqueada</DataTag>}
            {!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) && <DataTag>Bloq. na plataforma</DataTag>}
            {item.Produto.possui_cashback === 'SIM' && <TagCashBack> <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />CASHBACK</TagCashBack>}
          </DescricaoCell>
        ),
      },
      {
        title: "Tipo",
        key: "Campanha.tipo",
        dataIndex: "Campanha.tipo",
        width: "9%",
        // render: (_, item) => {
        //   if (item.Campanha) return item.Campanha.tipo

        //   return item.Produto.quantidade_por_fardo
        // }
      },
      {
        title: "Qtd.Venda",
        key: "quantidade",
        dataIndex: "quantidade",
        width: "10%",
        render: (_, item) => (
          <Input
            size="small"
            type="number"
            min="0"
            step="1"
            lang="en-150"
            disabled={(!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) || item.Produto.permite_tintometrico === "NAO" || item.Produto.venda_liberada === "NAO" || (qtdItens > 0 && tipo_produto !== null && item.Produto.tipo_produto !== "AMBOS" && item.Produto.tipo_produto !== tipo_produto) || !!item.Campanha)}
            //xvalue={this.state.focused === `quantidade:${rowProdutoKey(item)}` && +item.Produto.quantidade <= 0 ? "" : +item.Produto.quantidade}
            value={
              !item.Campanha ? (!!this.state.current[rowProdutoKey(item)] && this.state.current[rowProdutoKey(item)].quantidade > 0 ? this.state.current[rowProdutoKey(item)].quantidade : "") : item.Campanha.quantidade_por_fardo
            }
            className="text-center td-input"
            //onFocus={this.saveCurrentValue(item, "quantidade")}
            onChange={this.saveCurrentValue(item, "quantidade")}
            onBlur={event => {
              this.setState({ focused: null });

              const value = (typeof event === "string" ? event : event.target.value).trim();
              if (!value || isNaN(+value)) {
                item.quantidade = 0;
              }

              this.checkQuantidade(item);
            }}
          />
        ),
      }
    ];

    return (
      <Modal
        centered
        visible={visible}
        title={this.renderTitle()}
        footer={this.renderFooter()}
        onCancel={this.close}
        width={width}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName={classnames("chooser-produto-modal", {
          "chooser-produto-modal__produto": mode === MODE_PRODUTO,
        })}>
        <Spin spinning={!!this.state.loading || isLoading} style={{ minHeight: 200 }}>
          {mode === MODE_LINHA && (
            <Fragment>
              <p>
                Utilize o campo abaixo para encontrar seu produto <strong>OU</strong> utilize o filtro facilitado por
                tipo:
              </p>
              <SearchProdutoForm
                placeholder="Procure por código ou nome do produto"
                dataSource={this.ds}
                onSearch={this.searchProdutos}
                checked={this.state.isDescCamp}
                onChangeCheck={checked => this.setState({ isDescCamp: checked })}
                mode={mode}
                isFardo={this.state.isFardo}
              />
              <SubTitleH4>Filtrar por Tipo</SubTitleH4>
              <ChooserSimple loading={isLoading} items={tipos_fardo} onSelectRow={this.selectTipo} />
            </Fragment>
          )}
          {mode === MODE_PRODUTO && (
            <Fragment>
              <p>Selecione um ou mais produtos abaixo e depois pressione o botão "Adicionar Itens" para finalizar:</p>
              <SearchProdutoForm
                placeholder="Filtrar por código ou nome do produto"
                dataSource={this.ds}
                onSearch={this.searchProdutos}
                checked={this.state.isDescCamp}
                onChangeCheck={checked => this.setState({ isDescCamp: checked })}
                mode={mode}
                isFardo={this.state.isFardo}
                onChangeFardo={checked => this.setState({ isFardo: checked }, () => this.searchProdutos({ isFardo: this.state.isFardo }))}
              />
              <ChooserTable
                ref={_ref => (this._chooserProduto = _ref)}
                className="chooser-table__compact"
                loading={isLoading}
                paginationProps={{
                  style: {
                    float: "none",
                    textAlign: "center",
                    margin: "15px 0 0",
                  },
                }}
                locale={{
                  emptyText: "Nenhum produto disponível no momento para esta busca ou filtro de classe.",
                }}
                disabledRowKeys={(item) => {
                  if (!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) || item.Produto.permite_tintometrico === "NAO" || item.Produto.venda_liberada === "NAO" || (qtdItens > 0 && tipo_produto !== null && item.Produto.tipo_produto !== "AMBOS" && item.Produto.tipo_produto !== tipo_produto)) {
                    return true;
                  }
                }}
                rowKey={rowProdutoKey}
                columns={COLUMNS_PRODUTOS.map(mapColumn)}
                fetchOnMount={false}
                dataSource={this.ds}
                errorMessage="Não foi possível listar seus produtos no momento"
                onSelectRow={this.onSelectItem}
                allowMultipleSelection
              />
            </Fragment>
          )}
        </Spin>
      </Modal>
    );
  }
  _chooserProduto;
}
