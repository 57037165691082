import React, { Component, Fragment } from "react";
import { Spin, Modal, Tag, Button } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";

import { n } from "@util";
import { fetchKit } from "actions/pedido";
import { Item, Kit, MetaKit } from "stores/PedidoStore";

import KitTable from "./KitTable";
import MetaKitTable from "./MetaKitTable";

import "./KitEditor.less";

const KitTitleWrapper = styled.div`
  padding-right: 20px;

  & > strong {
    display: inline-block;
    font-weight: 600;
    margin-right: 9px;
  }
`;

const StyledTag = styled(Tag)`
  &.ant-tag {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.3px;
    padding: 1px 3px;
    margin: 0 4px 0 0;
    height: auto;
    cursor: default;
    vertical-align: text-top;
  }
`;

const KitTitle = observer(({ kit = null, isLoading = false, ...props }) => {
  const tags = [];
  if (!!kit) {
    if (+kit.adicional_qtd_minima > 0) {
      tags.push({
        key: "adicional_qtd_minima",
        color: "#AC1A22",
        children: `Adicional: ${kit.adicional_qtd_minima}%`,
      });
    }
    if (!!kit.tipo_qtd_total && kit.tipo_qtd_total.toUpperCase() !== "NENHUM") {
      tags.push({
        key: "tipo_qtd_total",
        color: "#AC1A22",
        children: `Tipo Qtd: ${kit.tipo_qtd_total}`,
      });
      if (+kit.qtd_inicial > 0) {
        tags.push({
          key: "qtd_inicial",
          color: "#AC1A22",
          children: `Qtd min: ${kit.qtd_inicial}`,
        });
      }
      if (+kit.qtd_final > 0) {
        tags.push({
          key: "qtd_final",
          color: "#AC1A22",
          children: `Qtd max: ${kit.qtd_final}`,
        });
      }
    }
    if (+kit.proporcao > 0) {
      tags.push({
        key: "proporcao",
        color: "#AC1A22",
        children: `Proporção: ${kit.proporcao}`,
      });
    }
    if (+kit.qtd_min_proporcao > 0) {
      tags.push({
        key: "qtd_min_proporcao",
        color: "#AC1A22",
        children: `Min. prop.: ${kit.qtd_min_proporcao}`,
      });
    }
  }

  return (
    <KitTitleWrapper {...props}>
      <strong>Kit de Campanha: {!kit ? "Carregando..." : kit.titulo_kit}</strong>
      {tags.length > 0 && tags.map(tag => !!tag.children && <StyledTag {...tag} />)}
    </KitTitleWrapper>
  );
});

@observer
export default class KitEditor extends Component {
  constructor(props) {
    super(props);

    this.kit = null;

    this.state = {
      mode: "add",
      kitBase: null,
      modalLoading: false,
      visible: false,
    };
  }
  componentWillUnmount() {
    this.kit = null;
  }
  async loadAndCreateKit(kit, itemsEdit = []) {
    if (!!this.props.pedido && !!this.props.pedido.isCampKit) {
      const { pedido, onPedidoError } = this.props;
      const codigo_cliente = `${pedido.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!pedido.data.loja_cliente ? `${pedido.data.loja_cliente}`.padStart(2, "0") : "01";
      const filial_faturamento = !!pedido.data.filial_faturamento
        ? `${pedido.data.filial_faturamento}`.padStart(2, "0")
        : "01";

      pedido.isLoading = true;

      try {
        const response = await fetchKit({
          codigo_cliente,
          loja_cliente,
          filial_faturamento,
          codigo_kit: typeof kit === "string" ? kit : kit.CampKit.codigo_kit || kit.codigo_kit,
          tipo_transp: pedido.tipoFreteTabela,
          op_logistica: pedido.data.op_logistica,
          cliente_retira: pedido.data.tipo_frete === "C" ? "NAO" : "SIM",
        });
        if (!!response.success) {
          const { items = [], metas = [], proporcao = [], abrangencia = [] } = response.data;
          this.kit = new Kit(kit, [], [], proporcao, abrangencia);
          this.kit.items = items.map(itemData => {
            const itemFiltered = itemsEdit.filter((item) => item.codigo_produto === itemData.CampKit.codigo_produto);

            return (new Item(itemData, pedido.policy.values, true,
              itemFiltered.length > 0 ? itemFiltered[0] : []
            ))
          });
          this.kit.metas = metas.map(meta => new MetaKit(this.kit, meta));
          if (!!itemsEdit && itemsEdit.length > 0) {
            const itemsEditCount = itemsEdit.length;
            for (let j = 0, jc = this.kit.items.length; j < jc; j++) {
              for (let i = 0; i < itemsEditCount; i++) {
                if (
                  itemsEdit[i].codigo_produto === this.kit.items[j].codigo_produto &&
                  itemsEdit[i].codigo_linha === this.kit.items[j].codigo_linha
                ) {
                  this.kit.items[j].quantidade = +itemsEdit[i].quantidade;
                  this.kit.items[j].desc_perfil = Math.max(+itemsEdit[i].desc_perfil, 0);
                  this.kit.items[j].desc_geren = Math.max(+itemsEdit[i].desc_geren, 0);
                  this.kit.items[j].calculaPreco();
                }
              }
            }
            this.kit.debouncedCalculaMetas();
            this.setState({ mode: "edit" });
          }
        } else {
          onPedidoError(`Kit indisponível para uso. Tente novamente mais tarde.`);
          this.close();
        }
      } catch (err) {
        console.warn(err);

        onPedidoError(`Kit indisponível para uso. Tente novamente mais tarde.`);
        this.close();
      } finally {
        pedido.isLoading = false;
      }
    }
  }
  open = (kit, items = []) => {
    this.loadAndCreateKit(kit, items);
    this.setState({ kitBase: kit.CampKit || kit, visible: true });
  };
  close = () => {
    this.setState({ mode: "add", visible: false, kitBase: null }, () => {
      this.kit = null;
    });
  };
  update = () => {
    const { pedido, onPedidoError } = this.props;
    const { mode = "add" } = this.state;
    try {
      this.setState({ modalLoading: true });

      if (this.kit.validateKit()) {
        if (mode === "edit") {
          pedido.removeCampKit(this.kit.codigo_kit);
        }

        const pendingItems = this.kit.items.filter(item => +item.quantidade > 0 && !!item.isValid);
        const totalPending = pendingItems.length;
        for (let j = 0; j < totalPending; j++) {
          try {
            pedido.addItem(pendingItems[j]);
          } catch (err) {
            onPedidoError(
              err.name === "PedidoError" && !!err.message
                ? err.message
                : `Não é possível adicionar este item a este pedido.`
            );
          }
        }

        if (!!this.kit.tipo_transporte && !!this.kit.modalidade_frete) {
          if (this.kit.modalidade_frete === "CABOTAGEM") {
            pedido.data.op_logistica = "C";
          }
          else {
            pedido.data.op_logistica = this.kit.tipo_transporte === "F" ? "F" : "L";
          }
        }

        if (+this.kit.prazo_medio_solvente > 0) {
          pedido.max_prazo_medio_solvente = +this.kit.prazo_medio_solvente;
          pedido.data.prazo_medio_solvente = +this.kit.prazo_medio_solvente;
        }

        if (+this.kit.prazo_medio_tinta > 0) {
          pedido.max_prazo_medio_tintacomp = +this.kit.prazo_medio_tinta;
          pedido.data.prazo_medio_tintacomp = +this.kit.prazo_medio_tinta;
        }

        if (!!this.kit.proporcaoKit && Array.isArray(this.kit.proporcaoKit) && this.kit.proporcaoKit.length > 0) {
          pedido.proporcao = this.kit.proporcaoKit;
        }

        if (!!this.kit.abrangenciaKit && Array.isArray(this.kit.abrangenciaKit) && this.kit.abrangenciaKit.length > 0) {
          pedido.abrangencia = this.kit.abrangenciaKit;
          pedido.etapa = Math.max(...this.kit.abrangenciaKit.map(ab => ab.etapa));
        }

        this.setState({ modalLoading: false }, () => setTimeout(this.close, 60));
      } else {
        this.setState({ modalLoading: false }, () => onPedidoError(`Não foi possível finalizar a edição deste KIT`));
      }
    } catch (err) {
      if (err.name === "PedidoError") {
        this.setState({ modalLoading: false }, () => onPedidoError(err.message));
      } else {
        console.error(err);

        this.setState({ modalLoading: false }, () => onPedidoError(`Não foi possível finalizar a edição deste KIT`));
      }
    }
  };
  render() {
    const { width = 980, pedido, onPedidoError } = this.props;
    const isValid = !!this.kit && !!this.state.kitBase && !pedido.isLoading && !!this.kit.isMinimumValid;

    return (
      <Modal
        centered
        visible={this.state.visible}
        title={<KitTitle kit={this.kit} isLoading={pedido.isLoading} />}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <strong>Peso Total: {!!this.kit ? n(this.kit.pesoTotal) : "..."}</strong>
            <div>
              <Button onClick={this.close}>Cancelar</Button>
              <Button
                type="primary"
                loading={pedido.isLoading || !!this.state.modalLoading}
                disabled={!isValid || +this.kit.pesoTotal === 0}
                onClick={this.update}>
                Selecionar
              </Button>
            </div>
          </div>
        }
        onCancel={this.close}
        width={width}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName="kit-editor-modal">
        <Spin spinning={!this.state.kitBase || pedido.isLoading}>
          <div style={{ minHeight: 430 }}>
            {!!this.kit && (
              <Fragment>
                <KitTable
                  kit={this.kit}
                  items={this.kit.items.map(item => item.asJSON)}
                  onPedidoError={onPedidoError}
                />
                {this.kit.metas.length > 0 && (
                  <MetaKitTable
                    kit={this.kit}
                    items={this.kit.metas.map(meta => meta.asJSON)}
                    onPedidoError={onPedidoError}
                  />
                )}
              </Fragment>
            )}
          </div>
        </Spin>
      </Modal>
    );
  }
}
