import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, SearchForm } from "components/FullTable";

const FormItem = Form.Item;

@observer
class DiarioBordoScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Diario de Bordo",
      uri: "/r/diario-bordo",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          dia_mes$0: "date",
          dia_mes$1: "date",
        }}
        filterParser={{
          dia_mes$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          dia_mes$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Filtrar por Data">
              {form.getFieldDecorator("dia_mes$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("dia_mes$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const columns = [
      {
        title: "Nome",
        dataIndex: "Diario.nome",
        key: "Diario.Nome",
        fixed: 'left',
        sorter: false,
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome do motorista" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome", value: `%${filter.value}%`, op: "LIKE" }),
        width: "15%",
      },
      {
        title: "Data",
        dataIndex: "Diario.dia_mes",
        dataType: "date",
        key: "Diario.dia_mes",
        sorter: true,
        width: "9%",
        filteredValue: this.ds.filterValue("dia_mes"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data(dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Diario.dia_mes",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Inicio Jornada",
        children: [
          {
            title: "Hora",
            dataIndex: "Diario.hora_inicial",
            width: "6%",
          },
          {
            title: "Km.",
            dataIndex: "Diario.km_inicial",
            width: "6%",
          },
        ]
      },
      {
        title: "1º Parada",
        children: [
          {
            title: "Mot.",
            dataIndex: "Diario.motivo_1",
            width: "6%",
          },
          {
            title: "Hr.ini",
            dataIndex: "Diario.hora_ini_1",
            width: "6%",
          },
          {
            title: "Hr.fin",
            dataIndex: "Diario.hora_final_1",
            width: "6%",
          },
        ]
      },
      {
        title: "2º Parada",
        children: [
          {
            title: "Mot.",
            dataIndex: "Diario.motivo_2",
            width: "6%",
          },
          {
            title: "Hr.ini",
            dataIndex: "Diario.hora_ini_2",
            width: "6%",
          },
          {
            title: "Hr.Fin",
            dataIndex: "Diario.hora_final_2",
            width: "6%",
          },
        ]
      },
      {
        title: "3º Parada",
        children: [
          {
            title: "Mot.",
            dataIndex: "Diario.motivo_3",
            width: "6%",
          },
          {
            title: "Hr.ini",
            dataIndex: "Diario.hora_ini_3",
            width: "6%",
          },
          {
            title: "Hr.Fin",
            dataIndex: "Diario.hora_final_3",
            width: "6%",
          },
        ]
      },
      {
        title: "4º Parada",
        children: [

          {
            title: "Mot",
            dataIndex: "Diario.motivo_4",
            width: "6%",
          },
          {
            title: "Hr.ini",
            dataIndex: "Diario.hora_ini_4",
            width: "6%",
          },
          {
            title: "Hr.Fin",
            dataIndex: "Diario.hora_final_4",
            width: "6%",
          },
        ]
      },
      {
        title: "5º Parada",
        children: [
          {
            title: "Mot",
            dataIndex: "Diario.motivo_5",
            width: "6%",
          },
          {
            title: "Hr.ini",
            dataIndex: "Diario.hora_ini_5",
            width: "6%",
          },
          {
            title: "Hr.Fin",
            dataIndex: "Diario.hora_final_5",
            width: "6%",
          },
        ]
      },
      {
        title: "6º Parada",
        children: [
          {
            title: "Mot",
            dataIndex: "Diario.motivo_6",
            width: "6%",
          },
          {
            title: "Hr.ini",
            dataIndex: "Diario.hora_ini_6",
            width: "6%",
          },
          {
            title: "Hor.Fin",
            dataIndex: "Diario.hora_final_6",
            width: "6%",
          },
        ]
      },
      {
        title: "7º Parada",
        children: [

          {
            title: "Mot",
            dataIndex: "Diario.motivo_7",
            width: "6%",
          },
          {
            title: "Hr.ini",
            dataIndex: "Diario.hora_ini_7",
            width: "6%",
          },
          {
            title: "Hr.Fin",
            dataIndex: "Diario.hora_final_7",
            width: "6%",
          },
        ]
      },
      {
        title: "Final da Jornada",
        children: [
          {
            title: "Hora",
            dataIndex: "Diario.hora_final_jornada",
            width: "6%",
          },
          {
            title: "Km.",
            dataIndex: "Diario.km_final",
            width: "6%",
          },
        ]
      }
    ];

    return (
      <div className="nps-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          scroll={{ x: '200%' }}
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.Diario.codigo}`}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter o Diário de bordo no momento"
        />
      </div>
    );
  }
}

export default withStore(DiarioBordoScreen);
