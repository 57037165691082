import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, DirectionsRenderer, Marker, Polyline, InfoWindow } from "@react-google-maps/api";
import { fetchNota } from "actions/notaFiscal";
import { Spin } from "antd";
import { calcularDistanciaEmKM } from "@util";
import PageTitle from "components/PageTitle";

const containerStyle = {
  height: "85vh",
};

const center = { lat: -23.55052, lng: -46.633308 }; // São Paulo (exemplo)

function RastreioScreen(props) {
  const [directions, setDirections] = useState(null);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState([]);
  const [notaFiscal, setNotaFiscal] = useState(null);
  const [infoWindow, setInfoWindow] = useState({ position: null, content: "" });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDpQ3SpWhGVvP4upaoWBg5UhU_sTZaQ32A",
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchNota(props.match.params.nota);

      if (response.success) {
        console.log('response.success -> ', response.data.itens);
        // setPoints(limparPontosDistantes(response.data.itens, 1));
        // setPoints(response.data.itens);
        setPoints(contarPontosPorDistancia(response.data.itens));
        setNotaFiscal(response.data.motorista);
      }
    } catch (error) {
      console.log("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  // Função para exibir a InfoWindow ao passar o mouse sobre a rota
  const handleMouseOver = (e, text) => {
    console.log('e -> ', e);
    console.log('text -> ', text);
    setInfoWindow({ position: e.latLng, content: text });
  };

  // Função para ocultar a InfoWindow ao sair da rota
  const handleMouseOut = () => {
    setInfoWindow({ position: null, content: "" });
  };

  useEffect(() => {
    if (!isLoaded || points.length < 2) return;

    const localPoints = [...points];

    localPoints.pop(); //remove o ultimo ponto para mostrar onde ele parou de rastrear

    const fetchRoutes = async () => {
      try {
        const directionsService = new window.google.maps.DirectionsService();
        const MAX_WAYPOINTS = 23; // Google permite 25 (origem + destino + 23 intermediários)
        let routePromises = [];

        for (let i = 0; i < localPoints.length; i += MAX_WAYPOINTS) {
          let partialPoints = localPoints.slice(i, i + MAX_WAYPOINTS + 2);
          if (partialPoints.length < 2) break; // Ignora segmentos sem origem e destino

          let waypoints = partialPoints.slice(1, -1).map((p) => ({ location: p, stopover: true }));

          routePromises.push(
            new Promise((resolve, reject) => {
              directionsService.route(
                {
                  origin: partialPoints[0],
                  destination: partialPoints[partialPoints.length - 1],
                  waypoints,
                  travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                  if (status === window.google.maps.DirectionsStatus.OK) {
                    resolve(result);
                  } else {
                    console.error("Erro ao buscar rota: " + status);
                    resolve(null);
                  }
                }
              );
            })
          );
        }

        const results = await Promise.all(routePromises);
        const filterResults = results.filter(Boolean);

        setDirections(filterResults); // Remove rotas que falharam
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoutes();
  }, [isLoaded, points]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  // Ajuste para o zoom e centro do mapa baseado nas rotas
  useEffect(() => {
    if (map && directions && directions.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      // Acumula todas as coordenadas (origem, destino e intermediárias) de todas as rotas
      directions.forEach((route) => {
        route.routes[0].legs.forEach((leg) => {
          bounds.extend(leg.start_location); // Adiciona o ponto inicial
          bounds.extend(leg.end_location);   // Adiciona o ponto final
        });
      });

      // Ajusta o mapa para a rota completa
      map.fitBounds(bounds);
    }
  }, [directions, map]);

  function contarPontosPorDistancia(pontos, distanciaAlvo = 10) {
    if (pontos.length === 0) return [];

    const resultado = [pontos[0]]; // Primeiro ponto sempre incluído
    let ultimoValido = pontos[0];
    let distanciaAcumulada = 0;

    for (let i = 1; i < pontos.length; i++) {
      const { lat, lng } = pontos[i];
      const distancia = calcularDistanciaEmKM(ultimoValido.lat, ultimoValido.lng, lat, lng);
      distanciaAcumulada += distancia;

      if (distanciaAcumulada >= distanciaAlvo) {
        resultado.push(pontos[i]);
        ultimoValido = pontos[i];
        distanciaAcumulada = 0;
      }
    }

    // Garante que o último ponto seja adicionado, se ainda não estiver na lista
    if (resultado[resultado.length - 1] !== pontos[pontos.length - 1]) {
      resultado.push(pontos[pontos.length - 1]);
    }

    return resultado;
  }

  const findClosestPoint = (latLng) => {
    if (!points.length) return null;

    let closestPoint = null;
    let minDistance = Infinity;

    points.forEach((point) => {
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(point.lat, point.lng),
        latLng
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = point;
      }
    });

    return closestPoint;
  };


  return isLoaded ? (
    <Spin spinning={loading}>
      {notaFiscal && (
        <PageTitle title={"Nota - " + notaFiscal.nota + " - " + notaFiscal.nome} />
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        onLoad={onLoad}
      >
        {/* Renderiza todas as rotas */}
        {directions && directions.map((dir, index) => (
          <>
            <DirectionsRenderer key={index} directions={dir} options={{ suppressMarkers: true, polylineOptions: { strokeOpacity: 0 } }} />
            <Polyline
              key={`polyline-${index}`}
              path={dir.routes[0].overview_path} // Linha da rota
              options={{
                strokeColor: "#004edf",
                strokeOpacity: 0.7,
                strokeWeight: 5,
                zIndex: 10,
              }}
              onMouseOver={(e) => {
                const closestPoint = findClosestPoint(e.latLng);
                if (closestPoint) {
                  handleMouseOver(e, `${closestPoint.timestamp}`);
                }
              }}
              onMouseOut={handleMouseOut}
            />
          </>
        ))}

        {/* Renderiza apenas o primeiro ponto */}
        {points.length > 0 && (
          <Marker position={points[0]}
            icon={{
              url: `${process.env.PUBLIC_URL}/img/rastreio-icon.png`,
              scaledSize: new window.google.maps.Size(50, 50), // Tamanho do ícone
            }}
            onMouseOver={(e) => {
              const closestPoint = findClosestPoint(e.latLng);
              if (closestPoint) {
                handleMouseOver(e, `${closestPoint.timestamp}`);
              }
            }}
          />
        )}

        {/* Renderiza apenas o último ponto */}
        {points.length > 1 &&
          <Marker position={points[points.length - 1]}
            icon={{
              url: `${process.env.PUBLIC_URL}/img/rastreio-icon.png`,
              scaledSize: new window.google.maps.Size(50, 50), // Tamanho do ícone
            }}
            onMouseOver={(e) => {
              const closestPoint = findClosestPoint(e.latLng);
              if (closestPoint) {
                handleMouseOver(e, `${closestPoint.timestamp}`);
              }
            }}
          // onMouseOut={handleMouseOut} 
          />
        }

        {/* {points.length > 1 && points.map((position, index) => (
          <Marker position={position} label={index} icon={{
            url: `${process.env.PUBLIC_URL}/img/rastreio-icon.png`,
            scaledSize: new window.google.maps.Size(50, 50), // Tamanho do ícone
          }} />
        ))} */}

        {infoWindow.position && (
          <InfoWindow options={{ headerContent: "Horário:" }} position={infoWindow.position} onCloseClick={handleMouseOut}>
            <div style={{ fontSize: "14px", fontWeight: "bold" }}>{infoWindow.content}</div>
          </InfoWindow>
        )}

      </GoogleMap>
    </Spin>
  ) : (
    <></>
  );
}

export default React.memo(RastreioScreen);
