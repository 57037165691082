import React, { PureComponent, Fragment } from "react";
import { Route } from "react-router-dom";
import UsersCargoAddScreen from "screens/transportador/userscargo/UsersCargoAddScreen";
import UsersCargoScreen from "screens/transportador/userscargo/UsersCargoScreen";

import MemorandoScreen from "screens/vendedor/relatorios/MemorandoScreen";

export default class Aprovador extends PureComponent {
  render() {
    return (
      <Fragment>
        <Route path="/memorando" component={MemorandoScreen} />
        <Route exact path="/transportadores/users-cargo" component={UsersCargoScreen} />
        <Route path="/transportadores/users-cargo/:edit" component={UsersCargoAddScreen} />
      </Fragment>
    );
  }
}