import React, { Component } from "react";
import { Modal, Row, Col, Form, Input, Checkbox } from "antd";
import classnames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react";

import { n, moneyFixed, maskedInputValue, dateFormat } from "@util";
import ProdutoTag, { ProdutoTagGroup } from "components/pedidos/ProdutoTag";

import "./UpdateProdutoModal.less";

const FormItem = Form.Item;

const formItemLayout = {};

const _desc = (policy, k, label, min = 0) => {
  const desc = !!policy ? moneyFixed(Math.max(+policy[`desc_${k}`], min)) : 0.0;
  if (+desc > min) {
    return `${label}: ${Math.abs(desc)}%`;
  }

  return null;
};

const ProdutoExtra = observer(({ item }) => {
  const tags = [];
  if (item.precoTabelaChanged) {
    tags.push({
      key: "preco_tabela",
      invalid: true,
      color: "#33af1a",
      children: `Prc. Tabela [expirado]`,
    });
  }
  if (!!item.canChangeDesconto) {
    const descExtra = ["logis", "extra", "retir", "finan", "mix", "kit"];
    for (let j = 0; j < descExtra.length; j++) {
      const k = descExtra[j];
      const l = k[0].toUpperCase() + k.substr(1);

      tags.push({
        key: `desc_${k}`,
        invalid: item.descontoErrors.indexOf(`desc_${k}`) !== -1,
        color: "#AC1A22",
        title: "Desconto " + l,
        children: _desc(item, k, l),
      });
    }

    if (!!item.desc_geren && +item.desc_geren > 0) {
      tags.push({
        key: `desc_geren`,
        invalid: item.descontoErrors.indexOf(`desc_geren`) !== -1,
        color: "#AC1A22",
        title: "Conta corrente",
        children: _desc(item, "geren", "Gerente"),
      });
    }
  }

  if (+item.desc_taxa !== 0) {
    tags.push({
      key: `desc_taxa`,
      color: +item.desc_taxa < 0 ? "#DE6710" : "#7AD166",
      title: +item.desc_taxa < 0 ? "Acréscimo Cond. Pagto" : "Decréscimo Cond. Pagto",
      children: _desc(item, "taxa", +item.desc_taxa < 0 ? "Acrésc" : "Decrésc", -100),
    });
  }

  return (
    <ProdutoTagGroup className="produto-tag-group">
      {item.hasCampanha && item.desc_camp > 0 && (
        <ProdutoTag color="#33af1a" invalid={item.campanhaErrors.length > 0}>
          {item.shortTagCampanha()}
        </ProdutoTag>
      )}
      {tags.length > 0 && tags.map(tag => !!tag.children && <ProdutoTag {...tag} />)}
      <ProdutoTag color="#AC1A22">Qtd. Emb: {Math.max(+item.quantidade_por_fardo, 1)}</ProdutoTag>
      {!!item.ultimo_preco && (
        <ProdutoTag color="#AC1A22" title="Último Preço">
          Últ. Preço: {n(item.ultimo_preco, 2, ".", "")}
        </ProdutoTag>
      )}
      {!!item.data_ultimo_preco && (
        <ProdutoTag color="#AC1A22" title="Último Faturamento">
          Últ. Fat.: {dateFormat(item.data_ultimo_preco)}
        </ProdutoTag>
      )}
    </ProdutoTagGroup>
  );
});

@observer
class UpdateFormProduto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: {},
    };
  }
  saveCurrentValue = field => event => {
    const { current } = this.state;
    const value = typeof event === "string" ? event : event.target.value;
    this.setState({ current: { ...current, [field]: value } });
  };
  updateItemValue = (field, onChange = null, tr = v => v) => event => {
    const { item } = this.props;
    item[field] = tr(typeof event === "string" ? event : event.target.value);

    if (!!onChange && typeof onChange === "function") {
      onChange(field, toJS(item[field]));
    }
  };
  hasChanged = field => {
    const { current } = this.state;
    const { item } = this.props;

    return !current[field] || `${current[field]}` !== `${toJS(item[field])}`;
  };
  checkQuantidade = () => {
    if (this.hasChanged("quantidade")) {
      const { item, onPedidoError } = this.props;
      try {
        item.checkQuantidade();
      } catch (err) {
        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `Não pode ser vendido fardo quebrado`
        );
      }
    }
  };
  checkDesconto = () => {
    if (this.hasChanged("desc_perfil")) {
      const { item, onPedidoError } = this.props;
      try {
        item.checkDesconto();
      } catch (err) {
        item.calculaPreco();

        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `Desconto não permitido para o perfil!`
        );
      }
    }
  };
  checkPreco = () => {
    if (this.hasChanged("preco_vendido")) {
      const { item, onPedidoError } = this.props;
      try {
        item.checkPreco();
      } catch (err) {
        item.calculaDesconto();

        onPedidoError(err.name === "PedidoError" && !!err.message ? err.message : `Valor abaixo do mínimo permitido!`);
      } finally {
        this.checkDesconto();
      }
    }
  };
  render() {
    const { isLoading = false, isB2B = false, item, onPedidoError, ...props } = this.props;

    const inputProps = {
      readOnly: isLoading || this.state.isLoading,
      disabled: !item.codigo_produto || !item.descricao,
      size: "small",
    };

    console.log('item -> ', item);

    return (
      <Form noValidate layout="vertical" className="form-produto-modal" {...props}>
        <Row gutter={12}>
          <Col span={9}>
            <FormItem {...formItemLayout} label="P.Compra">
              <Input
                {...inputProps}
                value={item.ped_compra}
                onFocus={this.saveCurrentValue("ped_compra")}
                onChange={this.updateItemValue("ped_compra")}
                maxLength={15}
              />
            </FormItem>
          </Col>
          <Col span={9}>
            <FormItem {...formItemLayout} label="Item PC">
              <Input
                {...inputProps}
                value={item.item_pc}
                onFocus={this.saveCurrentValue("item_pc")}
                onChange={this.updateItemValue("item_pc")}
                maxLength={6}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem {...formItemLayout} label="Prc. Tabela">
              <Input
                {...inputProps}
                type="number"
                min="0"
                lang="en-150"
                value={n(!!item.preco_tabela ? item.preco_tabela : 0, 2, ".", "")}
                readOnly={true}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <FormItem {...formItemLayout} label="Qtd.">
              <Input
                {...inputProps}
                type="number"
                min="0"
                step="1"
                lang="en-150"
                disabled={inputProps.disabled || !item.canChangeQuantidade || item.isFardo}
                value={item.quantidade}
                onFocus={this.saveCurrentValue("quantidade")}
                onChange={this.updateItemValue("quantidade")}
                onBlur={this.checkQuantidade}
              />
            </FormItem>
          </Col>
          {!isB2B && (
            <Col span={6}>
              <FormItem {...formItemLayout} label="Desc. Perfil">
                <Input
                  {...inputProps}
                  type="number"
                  min="0"
                  lang="en-150"
                  className={classnames({
                    "input-campanha": item.hasCampanha,
                    "input-error": item.descontoErrors.indexOf(`desc_perfil`) !== -1,
                  })}
                  disabled={inputProps.disabled || !item.canChangeDesconto}
                  value={item.desc_perfil}
                  onFocus={this.saveCurrentValue("desc_perfil")}
                  onChange={this.updateItemValue("desc_perfil", () => item.calculaPreco())}
                  onBlur={this.checkDesconto}
                />
              </FormItem>
            </Col>
          )}
          <Col span={6}>
            <FormItem {...formItemLayout} label="Preço Unit.">
              <Input
                {...inputProps}
                type="number"
                min="0"
                lang="en-150"
                className={classnames({
                  "input-campanha": item.hasCampanha,
                })}
                readOnly={inputProps.readOnly || !item.canChangePreco}
                value={n(!!item.preco_vendido ? item.preco_vendido : 0, 3, ".", "")}
                onFocus={this.saveCurrentValue("preco_vendido")}
                onChange={this.updateItemValue(
                  "preco_vendido",
                  () => item.calculaDesconto(),
                  v => maskedInputValue(v, 3)
                )}
                onBlur={this.checkPreco}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem {...formItemLayout} label="Total Item">
              <Input
                {...inputProps}
                type="number"
                min="0"
                lang="en-150"
                value={n(!!item.valor_total ? item.valor_total : 0, 2, ".", "")}
                readOnly={true}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={12}>
          {/* Bloqueia campo caso não seja da classe 030907 (base) */}
          {(props.pedido?.isLiberadoObsCarregamento && item.classe_produto === '030907') && (
            <Col span={24}>
              <FormItem {...formItemLayout} label="Obs. Ordem de Carregamento">
                <Input.TextArea
                  {...inputProps}
                  value={item.observacao_item}
                  onFocus={this.saveCurrentValue("observacao_item")}
                  onChange={this.updateItemValue("observacao_item")}
                  maxLength={250}>
                </Input.TextArea>
              </FormItem>
            </Col>
          )}
        </Row>
        <Row gutter={12}>
          {+item.perc_desc_gerencial > 0 && (
            <Col span={12}>
              <Checkbox
                {...inputProps}
                value={1}
                checked={+item.desc_geren > 0}
                disabled={
                  inputProps.disabled ||
                  !item.canChangeDesconto ||
                  +item.perc_desc_gerencial <= 0
                }
                onChange={event => {
                  item.desc_geren = event.target.checked ? Math.max(+item.perc_desc_gerencial, 0) : 0;
                  item.calculaPreco();
                }}>
                Conta corrente?
              </Checkbox>
            </Col>
          )}
          {item.canChangeDescCamp && (
            <Col span={12}>
              <Checkbox
                {...inputProps}
                value={1}
                checked={+item.campanha.desconto > 0}
                disabled={
                  inputProps.disabled ||
                  !item.canChangeDescCamp ||
                  this.tipoDescontoCampanha === "PRECO_POSICIONADO"
                }
                onChange={event => {
                  item.desc_camp = event.target.checked ? Math.max(+item.campanha.base_campanha, 0) : 0;
                  item.campanha.desconto = event.target.checked ? Math.max(+item.campanha.base_campanha, 0) : 0;
                  item.calculaPreco();
                }}>
                Desc. Campanha?
              </Checkbox>
            </Col>
          )}
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <ProdutoExtra item={item} />
          </Col>
        </Row>
      </Form>
    );
  }
  _inputCodigo;
}

@observer
export default class UpdateProdutoModal extends Component {
  constructor(props) {
    super(props);

    this.item = null;
    this.items = null;
    this.onUpdate = null;

    this.state = {
      visible: false,
    };
  }
  open = (item, onUpdate) => {
    this.item = item;
    this.onUpdate = onUpdate;
    this.setState({ visible: true });
  };
  close = () => {
    this.setState({ visible: false }, () => {
      this.item = null;
      this.onUpdate = null;
    });
  };
  update = () => {
    this.onUpdate(this.item);
    setTimeout(() => this.close(), 60);
  };
  render() {
    const { isLoading = false, isB2B = false, ...props } = this.props;
    const valid = !isLoading && !!this.item && this.item.isValid;

    return (
      <Modal
        centered
        width={690}
        title={!!this.item ? `Alterar ${this.item.codigo_produto} - ${this.item.descricao}` : `Alterar Item`}
        visible={this.state.visible}
        okText="Alterar"
        okButtonProps={{ disabled: !valid }}
        onOk={this.update}
        onCancel={this.close}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName="ant-modal-default update-produto-modal">
        {!!this.item && <UpdateFormProduto item={this.item} isLoading={isLoading} isB2B={isB2B} {...props} />}
      </Modal>
    );
  }
}
