import React, { Component } from "react";
import { Button, Icon, Dropdown, Menu, Modal, Tag } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter } from "components/FullTable";

import { fetchApi } from "actions";

@observer
class TreinamentoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Motoristas",
      uri: "/transportadores/usercargo",
      state: {
        isTransportadora: null
      }
    });

    this.state = {
      status: null,
    };
  }

  tryDelete = async id => {
    try {
      this.props.store.isLoading = true;
      const { data: response } = await fetchApi("/transportadores/usercargo/deletar/" + id);

      if (!!response.success) {
        Modal.success({
          title: "Sucesso!",
          content: `Motorista #${id} excluido com sucesso`,
        });
        this.ds.fetch();
      }
    } catch (error) {
      Modal.error({
        title: "Atenção",
        content: error,
      });
    } finally {
      this.props.store.isLoading = false;
    }
  };

  handleDelete = userCargoId => {
    Modal.confirm({
      title: "Atenção",
      content: `Você realmente deseja excluir esse motorista #${userCargoId}`,
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        this.tryDelete(userCargoId);
      },
    });
  };

  render() {
    const { history } = this.props;

    console.log('this.ds -> ', this.ds.state);

    const columns = [
      {
        title: "Cód.",
        dataIndex: "UserCargo.codigo",
        key: "codigo",
        sorter: true,
        width: "3%",
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "UserCargo.codigo", value: `${filter.value}` }),
      },
      {
        title: "Nome",
        dataIndex: "UserCargo.nome",
        key: "nome",
        sorter: true,
        width: "10%",
        className: "text-left",
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "UserCargo.nome", value: `${filter.value}` }),
        render: (item, row) => (
          <span>
            <div>
              {item}
            </div>
            {!this.ds.state.isTransportadora &&
              <div>
                <Tag color="blue">
                  {row.UserCargo.transportadora}/{row.Transportadora.nome_transportadora}
                </Tag>
              </div>
            }
          </span>
        )
      },
      {
        title: "Cpf",
        dataIndex: "UserCargo.cpf",
        key: "cpf",
        sorter: true,
        width: "4%",
        filteredValue: this.ds.filterValue("cpf"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por cpf" {...props} />,
        filterParser: filter => ({ ...filter, field: "UserCargo.cpf", value: `${filter.value}` }),
      },
      {
        title: "E-mail",
        dataIndex: "UserCargo.email",
        key: "email",
        sorter: true,
        width: "8%",
        filteredValue: this.ds.filterValue("email"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por email" {...props} />,
        filterParser: filter => ({ ...filter, field: "UserCargo.email", value: `${filter.value}` }),
      },
      {
        title: "Telefone",
        dataIndex: "UserCargo.telefone",
        key: "telefone",
        sorter: true,
        width: "4%",
        filteredValue: this.ds.filterValue("telefone"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por telefone" {...props} />,
        filterParser: filter => ({ ...filter, field: "UserCargo.telefone", value: `${filter.value}` }),
      },
      {
        title: "Login",
        dataIndex: "UserCargo.login",
        key: "login",
        sorter: true,
        width: "5%",
        filteredValue: this.ds.filterValue("login"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por login" {...props} />,
        filterParser: filter => ({ ...filter, field: "UserCargo.login", value: `${filter.value}` }),
      },
      {
        title: "",
        key: "actionbar",
        width: "5%",
        export: false,
        render: (_, item) => {
          const menu = (
            <Menu onClick={() => this.handleDelete(item.UserCargo.codigo)}>
              <Menu.Item key="menu-delete">
                <Icon type="delete" />
                Deletar motorista
              </Menu.Item>
            </Menu>
          );
          return (
            <Dropdown.Button
              onClick={() => history.push(`/transportadores/users-cargo/${item.UserCargo.codigo}`)}
              title="Editar Motorista"
              size="small"
              type="primary"
              overlay={menu}>
              <Icon type="edit" />
              Editar
            </Dropdown.Button>
          );
        },
      },
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name}>
          <Button onClick={() => history.push(`/transportadores/users-cargo/add`)} type="primary" icon="plus">
            Novo Motorista
          </Button>
        </PageTitle>
        <FullTable
          rowKey={item => `${item.UserCargo.codigo}:${item.UserCargo.nome}`}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter os treinamentos no momento"
        />
      </div>
    );
  }
}

export default withStore(TreinamentoScreen);
